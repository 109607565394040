<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">Create User</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>


        <div id="error" class="card border-danger" v-show="error">
          <div class="card-header bg-danger border-danger">

              <span class="text-white font-weight-bolder">
              <i class="fa fa-exclamation-triangle"></i>
               &nbsp;
              {{ error_heading }}
              </span>

          </div>
          <div class="card-body border-danger text-dark font-weight-bold">

            {{ error_text }}

          </div>
        </div>

        <div>

          <b-form-row class="mb-3">

            <b-col cols="6">
              <label class="">First Name</label>
              <b-input v-model="$v.user.first_name.$model" class="mb-2 mr-sm-2 mb-sm-0" :state="validateState('first_name')"></b-input>
              <b-form-invalid-feedback v-if="!$v.user.first_name.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

            <b-col cols="6">
              <label class="">Last Name</label>
              <b-input v-model="$v.user.last_name.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('last_name')"></b-input>
              <b-form-invalid-feedback v-if="!$v.user.last_name.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

          </b-form-row>

          <b-form-row class="mb-3">

            <b-col cols="6">
              <label class="">Username</label>
              <b-input v-model="$v.user.username.$model" class="mb-2 mr-sm-2 mb-sm-0" :state="validateState('username')" v-on:input="resetServerError('username')"></b-input>
              <b-form-invalid-feedback v-if="!$v.user.username.required">This is a required field.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="server_errors.username">{{server_errors.username}}</b-form-invalid-feedback>
            </b-col>

            <b-col cols="6">
              <label class="">Email</label>
              <b-input type="email" v-model="$v.user.email.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('email')"></b-input>
              <b-form-invalid-feedback v-if="!$v.user.email.required">This is a required field.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.user.email.email">Please enter a valid email address.</b-form-invalid-feedback>
            </b-col>

          </b-form-row>

          <!--<b-form-row class="mb-3">

            <b-col cols="6">
              <label class="">Password</label>
              <b-input type="password" v-model="$v.user.password.$model" class="mb-2 mr-sm-2 mb-sm-0" :state="validateState('password')" v-on:input="resetServerError('password')"></b-input>
              <b-form-invalid-feedback v-if="!$v.user.password.required">This is a required field.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.user.password.minLength">Password must be at least 6 characters long.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="server_errors.password">{{server_errors.password}}</b-form-invalid-feedback>
            </b-col>

            <b-col cols="6">
              <label class="">Password Confirm</label>
              <b-input type="password" v-model="$v.user.password_confirm.$model" class="mb-2 mr-sm-2 mb-sm-0"  :state="validateState('password_confirm')" v-on:input="resetServerError('password_confirm')"></b-input>
              <b-form-invalid-feedback v-if="!$v.user.password_confirm.required">This is a required field.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.user.password_confirm.minLength">Password must be at least 6 characters long.</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="!$v.user.password_confirm.sameAsPassword">Passwords must match</b-form-invalid-feedback>
              <b-form-invalid-feedback v-if="server_errors.password_confirm">{{server_errors.password_confirm}}</b-form-invalid-feedback>
            </b-col>

          </b-form-row>-->

          <b-form-row>

            <b-col cols="6">
              <label class="">Permission Group/s</label>
              <b-form-select multiple v-model="$v.user.groups.$model" :options="options.groups" :state="validateState('groups')">
              </b-form-select>
              <b-form-invalid-feedback v-if="!$v.user.groups.required">This is a required field.</b-form-invalid-feedback>
            </b-col>

            <!--<b-col cols="6">
              <label class="">Active</label>
              <b-form-select v-model="$v.user.active.$model" :options="options.yes_no" :state="validateState('active')">
              </b-form-select>
              <b-form-invalid-feedback v-if="!$v.user.active.required">This is a required field.</b-form-invalid-feedback>
            </b-col>-->

          </b-form-row>

        </div>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
          <b-button variant="success" @click="createUser" class="float-right">Create User</b-button>
        </template>
      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import { validationMixin } from 'vuelidate'
  import { required, email, minLength, sameAs } from "vuelidate/lib/validators";

  export default {
    name: 'UserCreate',
    components: {Id},
    mixin: [validationMixin],
    props: {
      caption: {
        type: String,
        default: 'Create'
      },
    },
    data: () => {
      return {
        user: {
          first_name: '',
          last_name: '',
          username: '',
          email: '',
          active: null,
          password: '',
          password_confirm: '',
          groups: []
        },
        error: false,
        error_heading: '',
        error_text: '',
        server_errors: {
          first_name: null,
          last_name: null,
          username: null,
          email: null,
          active: null,
          password: null,
          password_confirm: null,
          groups: null
        },
        options: {
          yes_no: [
            {
              value: null,
              text: 'Please select'
            },
            {
              value: true,
              text: 'Yes'
            },
            {
              value: false,
              text: 'No'
            }
          ],
          groups: []
        }
      }
    },
    validations() {

      return {
        user: {
          first_name: {
            required
          },
          last_name: {
            required
          },
          username: {
            required,
            serverRequired () { return Boolean(!this.server_errors.username) }
          },
          email: {
            required,
            email
          },
          active: {
            required
          },
          password: {
            required,
            minLength: minLength(6),
            serverRequired () { return Boolean(!this.server_errors.password) }
          },
          password_confirm: {
            required,
            sameAsPassword: sameAs('password'),
            serverRequired () { return Boolean(!this.server_errors.password_confirm) }
          },
          groups: {
            required
          }
        }
      }

    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      validateState(name) {

        if (this.$v.user[name]) {
          const {$dirty, $error} = this.$v.user[name];
          return $dirty ? !$error : null;
        }
      },
      resetServerError(name) {

        if (this.server_errors[name]) {
          this.server_errors[name] = null;
        }

      },
      getGroups() {
        axios.get('/users/groups/').then(
          response => (
            this.options.groups = response.data.groups
          )
        ).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            console.log('premium error');
            console.log(error.response)
          })
      },
      createUser() {

        this.error = false;

        this.$v.$touch();

        if(this.$v.$anyError) {

          window.scrollTo(document.querySelector('#error').offsetTop, 100);
          this.error = true;
          this.error_heading = 'There are errors on the form';
          this.error_text = 'Please fix the errors and re-submit the form';
          return

        } else {

          const post_data = JSON.parse(JSON.stringify(this.user));

          axios.post('/users/', post_data).then(
            response => {
              this.user = response.data
              let alert = {
                type: 'success',
                text: 'User : ' + this.user.first_name + ' ' + this.user.last_name + ' was created successfully!'
              };

              this.$store.commit(
                'setGlobalAlert',
                alert
              )
              this.$router.replace('/users')
            }
          ).catch(error => {

            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }

            if (error.response.status === 400) {

              window.scrollTo(document.querySelector('#error').offsetTop, 100);
              this.error = true;
              this.error_heading = 'There are errors on the form';
              this.error_text = 'Please fix the errors and re-submit the form';

              if (error.response.data) {

                //this.server_errors = error.response.data

                for (var key in error.response.data){
                  this.server_errors[key] = error.response.data[key][0]
                }
                this.$v.$reset();
                this.$v.$touch();

              }

            }

            console.log('premium error');
            console.log(error.response)
          });

        }
      }
    },
    mounted() {
      //this.getGroups()
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
